export default {
  blogerName: 'KLP',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/klp666',
    },
    {
      name: 'telegram',
      url: 'https://t.me/KLP666ANONS',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@klp6663',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs.com/c65fcb1e8',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs.com/c577b45a8',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs.com/cb3820b0b',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/cc0184d65',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/c13952e94',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cc5c11845',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/c8e147006',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir23.com/c2996023a',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-mutlukir.com/cb4f5df01',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-irrs01.com/c5e3e6d4b',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-nxoyfjmrn.com/c3b990a11',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-izpihgrzed.com/c3f16d9b7',
      gameTitle: 'Doors Of Sol (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>KLP</strong> и получи 125 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'KLP',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>125FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
